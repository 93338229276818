window.jQuery = require('jquery');

// window.Tether = require('tether');
window.Bootstrap = require('bootstrap');
window.Owlcarousel = require('owl.carousel');
window.MatchHeight = require('jquery-match-height');
window.MatchHeight = require('jquery.mmenu');

(function(d, s, id) {
  var js, fjs = d.getElementsByTagName(s)[0];
  if (d.getElementById(id)) return;
  js = d.createElement(s); js.id = id;
  js.src = "//connect.facebook.net/zh_TW/sdk.js#xfbml=1&version=v2.9&appId=1839316096318565";
  fjs.parentNode.insertBefore(js, fjs);
}(document, 'script', 'facebook-jssdk'));

jQuery(window).load(function() {
	jQuery(".se-pre-con").fadeOut("slow");;
});

jQuery(document).ready(function(){
	jQuery(window).scroll(function(){
		if (jQuery(this).scrollTop() > 100) {
			jQuery('#totop').fadeIn();
		} else {
			jQuery('#totop').fadeOut();
		}
	});
	jQuery('#totop').click(function(){
		jQuery('html, body').animate({scrollTop : 0},800);
		return false;
	});
});

jQuery(document).ready(function() {
  jQuery("#home-slider").owlCarousel({
  	margin : 0,
		autoplay : true,
		autoplayTimeout : 4000,
		loop : true,
		// nav : true, 
		// navText: [
  //     "../images/prev.png",
  //     "../images/next/png"
  //   ],
    slideSpeed : 1000,
    paginationSpeed : 4000,
    items: 1,
    autoHeight:true
    // singleItem: true,
  });
  owl = jQuery('#home-slider').owlCarousel();
	jQuery(".prev").click(function () {
	    owl.trigger('prev.owl.carousel');
	});

	jQuery(".next").click(function () {
	    owl.trigger('next.owl.carousel');
	});

	jQuery("#service-slider").owlCarousel({
		// loop : true,
		margin : 20,
		// center : true,
		nav : true,
		navText: [
      "<i class='fa fa-angle-left'></i>",
      "<i class='fa fa-angle-right'></i>"
    ],
		dots : false,
		items : 4,
		responsiveClass : true,
	  responsive : {
	      0 : {
	          items: 1
	      },
	      600 : {
	          items: 2
	      },
	      767 : {
	          items: 3
	      },
	      1199 : {
	          items: 4
	      }
	  }
	});

	jQuery("#inquiry-slider").owlCarousel({
		// loop : true,
		margin : 40,
		// center : true,
		nav : true,
		navText: [
      "<i class='fa fa-angle-left'></i>",
      "<i class='fa fa-angle-right'></i>"
    ],
		dots : false,
		items : 2,
		responsiveClass : true,
	  responsive : {
	      0 : {
	          items: 1
	      },
	      767 : {
	          items: 2
	      }
	  }
	});

	jQuery("#customer-slider").owlCarousel({
		margin : 20,
		nav : true,
		navText: [
      "<i class='fa fa-angle-left'></i>",
      "<i class='fa fa-angle-right'></i>"
    ],
		dots : false,
		items : 6,
		responsiveClass : true,
	  responsive : {
	      0 : {
	          items: 2
	      },
	      767 : {
	          items: 3
	      },
	      991 : {
	          items: 4
	      },
	      1199 : {
	          items: 5
	      },
	      1400 : {
	          items: 6
	      }
	  }
	});

	jQuery("#other-service-slider").owlCarousel({
		margin : 0,
		nav : true,
		navText: [
      "<i class='fa fa-angle-left'></i>",
      "<i class='fa fa-angle-right'></i>"
    ],
		dots : false,
		autoplay : false,
		autoplayTimeout : 4000,
		loop : true,
    slideSpeed : 1000,
    paginationSpeed : 4000,
    items: 1,
	});

  jQuery("#other-news-slider").owlCarousel({
    margin : 0,
    nav : true,
    navText: [
      "<i class='fa fa-angle-left'></i>",
      "<i class='fa fa-angle-right'></i>"
    ],
    dots : false,
    autoplay : false,
    autoplayTimeout : 4000,
    loop : true,
    slideSpeed : 1000,
    paginationSpeed : 4000,
    items: 1,
  });

	jQuery(".map-frame").on({
      mouseleave: function() {
          jQuery(this).find('iframe').css('pointer-events', 'none');
      },
      click: function() {
          jQuery(this).find('iframe').css('pointer-events', 'auto');
      }
  }).find('iframe').css('pointer-events', 'none');

});

jQuery(function() {
	jQuery('nav#menu').mmenu();
});

jQuery(function() {
	jQuery(".customer-item , .center-box , .service-box p , .equalheight").matchHeight({
	  byRow: true,
	  property: 'height',
	  target: null,
	  remove: false
	});
});

jQuery('img.svg').each(function(){
  var $img = jQuery(this);
  var imgID = $img.attr('id');
  var imgClass = $img.attr('class');
  var imgURL = $img.attr('src');

  jQuery.get(imgURL, function(data) {
      // Get the SVG tag, ignore the rest
      var $svg = jQuery(data).find('svg');

      // Add replaced image's ID to the new SVG
      if(typeof imgID !== 'undefined') {
          $svg = $svg.attr('id', imgID);
      }
      // Add replaced image's classes to the new SVG
      if(typeof imgClass !== 'undefined') {
          $svg = $svg.attr('class', imgClass+' replaced-svg');
      }

      // Remove any invalid XML tags as per http://validator.w3.org
      $svg = $svg.removeAttr('xmlns:a');

      // Replace image with new SVG
      $img.replaceWith($svg);

  }, 'xml');
});

(function (jQuery) {
  jQuery.fn.floatLabels = function (options) {
    var self = this;
    var settings = jQuery.extend({}, options);

    // Event Handlers
    function registerEventHandlers() {
        self.on('input keyup change', 'input, textarea', function () {
            actions.swapLabels(this);
        });
    }

    // Actions
    var actions = {
        initialize: function() {
            self.each(function () {
                var $this = jQuery(this);
                var $label = $this.children('label');
                var $field = $this.find('input,textarea').first();

                if ($this.children().first().is('label')) {
                    $this.children().first().remove();
                    $this.append($label);
                }

                var placeholderText = ($field.attr('placeholder') && $field.attr('placeholder') != $label.text()) ? $field.attr('placeholder') : $label.text();

                $label.data('placeholder-text', placeholderText);
                $label.data('original-text', $label.text());

                if ($field.val() == '') {
                    $field.addClass('empty')
                }
            });
        },
        swapLabels: function (field) {
            var $field = jQuery(field);
            var $label = jQuery(field).siblings('label').first();
            var isEmpty = Boolean($field.val());

            if (isEmpty) {
                $field.removeClass('empty');
                $label.text($label.data('original-text'));
            }
            else {
                $field.addClass('empty');
                $label.text($label.data('placeholder-text'));
            }
        }
    }

    // Initialization
    function init() {
        registerEventHandlers();

        actions.initialize();
        self.each(function () {
            actions.swapLabels(jQuery(this).find('input,textarea').first());
        });
    }
    init();

    return this;
  };

  jQuery(function () {
      jQuery('.float-label-control').floatLabels();
  });
})(jQuery);

